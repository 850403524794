@use 'mixins/theme-builder.mixin';
@use 'functions/theme-builder.function' as theme-builder-fn;

$akelius-legacy-palette: theme-builder-fn.create-flat-palette(#357ca2, black);
$akelius-legacy-warn-palette: theme-builder-fn.create-flat-palette(#c4401a, white);
$akelius-legacy-theme: theme-builder-fn.create-theme(false, $akelius-legacy-palette, $akelius-legacy-warn-palette);

// Theme variables
.akelius-legacy-theme {
  --primary-color: #357ca2; // changed
  --primary-text-color: black; // changed
  --primary-hover-color: #2c6685;
  --primary-hover-text-color: white;

  --font-standard-color: black;
  --font-secondary-text-color: black;
  --font-link-color: black;
  --font-link-hover-color: black;
  --font-link-weight: 400; // changed
  --font-standard-weight: 400;
  --link-text-decoration: none;

  --accent-color: #357ca2;
  --accent-text-color: white;

  --status-warning-color: #efb71f;

  --status-success-color: #117b42;

  --status-error-color: #c4401a;

  --disabled-color: #b0bac1;
  --line-color: #e2e5e8;

  --background: white;

  --status-info-color: #e2e5e8; // changed
  --status-info-text-color: white;

  // private colors - move these into a separate file?

  --form-field-underline-color: black;

  --advert-background: #efb71f; // changed
  --advert-text: black;

  --primary-button-border-color: black;
  --primary-button-border-hover-color: #2c6685;

  --button-standard-background-color: white;
  --button-standard-text-color: #357ca2; // changed
  --button-standard-border-color: #a3c3d4; // changed

  --button-standard-hover-background-color: #a3c3d4;
  --button-standard-hover-text-color: #172b53; // changed
  --button-standard-hover-border-color: #a3c3d4; // changed

  --button-border-radius: 3px;
  --button-horizontal-padding: 8px; // small

  --icon-font-color: #172b53; // changed
  --header-icon-color: #a3c3d4;

  --slide-accent-color: black;

  --font-family: Verdana;

  @include theme-builder.override-theme-colors($akelius-legacy-theme);

  button.mat-flat-button.mat-button-base.mat-primary,
  a.mat-flat-button.mat-button-base.mat-primary {
    &:not([disabled]) {
      color: white;
    }
  }

  button.mat-icon-button.icon-button-selected,
  a.mat-icon-button.icon-button-selected {
    background-color: #a3c3d4;
  }

  button.mat-icon-button.header-button,
  a.mat-icon-button.header-button {
    color: #172b53;

    &[aria-expanded='true'] {
      color: white;
      background-color: #172b53;
    }
  }

  .mat-tab-group,
  .mat-tab-nav-bar {
    &:not(.dummy-specificity-class) {
      .mat-tab-label.mat-tab-label-active,
      .mat-tab-link.mat-tab-label-active {
        background-color: #a3c3d4;
      }
    }
  }

  .mat-slide-toggle:not(.dummy-specificity-class) {
    &.mat-checked {
      .mat-slide-toggle-bar {
        &::before {
          background-color: white;
        }
      }

      .mat-slide-toggle-thumb {
        background-color: white;
      }
    }

    &:not(.mat-checked) {
      .mat-slide-toggle-bar {
        box-shadow: 0 0 0 2px var(--primary-color) inset;

        &:after {
          background-color: #357ca2;
        }
      }

      .mat-slide-toggle-thumb {
        background-color: #357ca2;
      }
    }
  }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    &:not(.dummy-specificity-class) .mat-step-header .mat-step-icon.mat-step-icon-selected {
      background: #a3c3d4;
    }
  }

  .mat-button-toggle-group.mat-button-toggle-group-appearance-standard
    .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked:not(.dummy-specificity-class)
    .mat-button-toggle-button {
    background-color: #a3c3d4;
  }
}
